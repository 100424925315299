a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-shadow: 0px 0px 2px #d3d3d3;
}

.MuiButton-root.MuiButton-contained{
  margin: auto 0px 0px;
}
